import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule, HeaderModule, LayoutModule, NavigationModule, FeedbackModule } from '@skykick/core';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';

/**
 * Shared components and services that get used throughout the application.
 */
@NgModule({
    declarations: [ConfirmationModalComponent],
    imports: [
        HeaderModule,
        NavigationModule,
        LayoutModule,
        ButtonsModule,
        TranslateModule,
        FeedbackModule
    ],
    exports: [
        // Provides access to the sk-header component directive
        HeaderModule,
        // Provides access to the primary, secondary, and accordian style navigation component directives
        NavigationModule,
        // Provides a standardized layout for various common components.
        LayoutModule,
        // Provides access to various button directives
        ButtonsModule,
        // Provides access to the 'translate' pipe directive among other things.
        TranslateModule,
        FeedbackModule,
        ConfirmationModalComponent,
    ],
})
export class SharedModule {}
