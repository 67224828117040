import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class BannerSessionService {
    private backupLandingBannerKey: string = 'skykick:syndication:backup:landing:banner';
    private skIdTokenKey = 'sk_id_token';

    constructor(private localStorage: LocalStorageService, private cookieService: CookieService) {}

    closeBackupLandingBannerForCurrentSession() {
        const currentSessionId = this.getSessionId();
        if (currentSessionId) this.localStorage.set(this.backupLandingBannerKey, currentSessionId);
    }

    removeBackupLandingBannerKeyOnNewSession(): void {
        const currentSessionId = this.getSessionId();

        if (currentSessionId && currentSessionId !== this.localStorage.get(this.backupLandingBannerKey)) {
            this.localStorage.remove(this.backupLandingBannerKey);
        }
    }

    checkIfBackupLandingFeedbackWasClosedForSession = () => !this.localStorage.get(this.backupLandingBannerKey);

    /**
     * The 'sid' is unique for each session
     * @returns sessionId
     */
    private getSessionId(): string | null {
        const jwtAccessToken = this.cookieService.get(this.skIdTokenKey);
        if (!jwtAccessToken) {
            return null;
        }

        const decodedJwt = jwtDecode<any>(jwtAccessToken);

        return decodedJwt['sid'];
    }
}
